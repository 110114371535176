body {
  background: black;
  color: white;
}

.section {
  position: relative;
  width: 100%;
}

.center {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  inset: 0;
  overflow: hidden;
  padding: 10px 16px 0;
}


.banner-container, .keys-container, .sword-container {
  max-width: 100%;
}

.banner {
  width: 100%;
  height: 30%;
  object-fit: contain;
  object-position: center;
}
.keys {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sword {
  width: 100%;
  height: 15%;
  object-fit: contain;
  object-position: center;
}

.ndnmk {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 35%;
}

@media (min-aspect-ratio: 1) {
  .container {
    position: relative;
    max-width: 660px;
    margin: 0 auto;
    padding: 32px 64px 0px;
  }

  .banner {
    height: auto;
  }

  .keys {
    height: 300px;
    margin-top: 64px;
  }

  .sword {
    height: 150px;
    margin-top: 64px;
    width: auto;
  }

  .ndnmk {
    position: absolute;
    z-index: 1;
    padding: 4px 8px;
    background-color: black;
    max-width: 200px;
  }
}